
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsSourceControl extends Vue {
        // Data function
        public data() {
            return {
                bitBucketImagePathAndName:
                    require("@/assets/images/bitbucket.png"),

                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(30, 10%, 40%), hsl(0, 0%, 22%))",

                gitHubImagePathAndName:
                    require("@/assets/images/github.png"),

                gitGrakenImagePathAndName:
                    require("@/assets/images/gitkraken.png"),

                sourceTreeImagePathAndName:
                    require("@/assets/images/sourcetree.png"),
            };
        }
    }
