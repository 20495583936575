
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsFuture extends Vue {
        // Data function
        public data() {
            return {
                blenderImagePathAndName:
                    require("@/assets/images/blender.png"),

                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(90, 10%, 40%), hsl(0, 0%, 22%))",

                dockerImagePathAndName:
                    require("@/assets/images/docker.png"),

                kotlinImagePathAndName:
                    require("@/assets/images/kotlin.png"),

                mobxImagePathAndName:
                    require("@/assets/images/mobx.png"),

                reactPathAndName:
                    require("@/assets/images/react.png"),

                reduxImagePathAndName:
                    require("@/assets/images/redux.png"),

                sketchImagePathAndName:
                    require("@/assets/images/sketch.png"),

                tableauImagePathAndName:
                    require("@/assets/images/tableau.png"),
            };
        }
    }
