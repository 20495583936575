
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsGraphics extends Vue {
        // Data function
        public data() {
            return {
                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(60, 10%, 40%), hsl(0, 0%, 22%))",

                drawIoImagePathAndName:
                    require("@/assets/images/draw_io.png"),

                lucidChartImagePathAndName:
                    require("@/assets/images/lucid_chart.png"),

                mayaImagePathAndName:
                    require("@/assets/images/maya.png"),

                photoshopImagePathAndName:
                    require("@/assets/images/photoshop.png"),

                threeDStudioMaxImagePathAndName:
                    require("@/assets/images/3ds_max.png"),
            };
        }
    }
