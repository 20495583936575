
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsAudioAndVideo extends Vue {
        // Data function
        public data() {
            return {

                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(270, 10%, 40%), hsl(0, 0%, 22%))",

                audacityPathAndName:
                    require("@/assets/images/audacity.png"),

                youTubePathAndName:
                    require("@/assets/images/you_tube.png"),

            };
        }
    }
