
    import SkillsAudioAndVideo from "@/components/skills/skills_audio_and_video.vue";
    import SkillsEditorAndIde from "@/components/skills/skills_editor_and_ide.vue";
    import SkillsEngineAndLibrary from "@/components/skills/skills_engine_and_library.vue";
    import SkillsFullStack from "@/components/skills/skills_full_stack.vue";
    import SkillsFuture from "@/components/skills/skills_future.vue";
    import SkillsGraphics from "@/components/skills/skills_graphics.vue";
    import SkillsLanguage from "@/components/skills/skills_language.vue";
    import SkillsSourceControl from "@/components/skills/skills_source_control.vue";
    import SkillsTeam from "@/components/skills/skills_team.vue";
    import { Component, Vue } from "vue-property-decorator";

    // *****************************************************************************************************************
    @Component({
        components: {
            SkillsAudioAndVideo,
            SkillsEditorAndIde,
            SkillsEngineAndLibrary,
            SkillsFullStack,
            SkillsFuture,
            SkillsGraphics,
            SkillsLanguage,
            SkillsSourceControl,
            SkillsTeam
        },
    })

    // *****************************************************************************************************************
    export default class AboutMySkills extends Vue {
        // Data function
        public data() {
            return {
                audioAndVideoIconPathAndName:
                    require("@/assets/images/icon_audio_and_video.png"),

                cementMixerImagePathAndName:
                    require("@/assets/images/cement_mixer.png"),

                denimImagePathAndName:
                    require("@/assets/images/denim.png"),

                drainageImagePathAndName:
                    require("@/assets/images/drainage.png"),

                editorIconPathAndName:
                    require("@/assets/images/icon_editor.png"),

                engineIconPathAndName:
                    require("@/assets/images/icon_engine.png"),

                ezBarImagePathAndName:
                    require("@/assets/images/ez_bar.png"),

                fenceImagePathAndName:
                    require("@/assets/images/fence.png"),

                finishedImagePathAndName:
                    require("@/assets/images/finished.png"),

                frontendIconPathAndName:
                    require("@/assets/images/icon_frontend.png"),

                futureIconPathAndName:
                    require("@/assets/images/icon_future.png"),

                graphicsIconPathAndName:
                    require("@/assets/images/icon_graphics.png"),

                miniDiggerImagePathAndName:
                    require("@/assets/images/mini_digger.png"),

                roadAheadImagePathAndName:
                    require("@/assets/images/road_ahead.png"),

                sourceControlIconPathAndName:
                    require("@/assets/images/icon_source_control.png"),

                teamIconPathAndName:
                    require("@/assets/images/icon_team.png"),
            };
        }
    }
