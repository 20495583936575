
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsEditorAndIde extends Vue {
        // Data function
        public data() {
            return {
                androidStudioImagePathAndName:
                    require("@/assets/images/android_studio.png"),

                beyondCompareImagePathAndName:
                    require("@/assets/images/beyond_compare.png"),

                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(90, 10%, 40%), hsl(0, 0%, 22%))",

                doxygenPathImageAndName:
                    require("@/assets/images/doxygen.png"),

                jetBrainsRiderImagePathAndName:
                    require("@/assets/images/jet_brains_rider.png"),

                notepadPlusPlusImagePathAndName:
                    require("@/assets/images/notepad_plus_plus.png"),

                visualStudio2019ImagePathAndName:
                    require("@/assets/images/visual_studio_2019.png"),

                visualStudioCodeImagePathAndName:
                    require("@/assets/images/visual_studio_code.png"),
            };
        }
    }
