
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsTeam extends Vue {
        // Data function
        public data() {
            return {
                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(180, 10%, 40%), hsl(0, 0%, 22%))",

                confluenceImagePathAndName:
                    require("@/assets/images/confluence.png"),

                googleAccountImagePathAndName:
                    require("@/assets/images/google_account.png"),

                jiraImagePathAndName:
                    require("@/assets/images/jira.png"),

                linkedInImagePathAndName:
                    require("@/assets/images/linkedin.png"),

                slackImagePathAndName:
                    require("@/assets/images/slack.png"),

                skypeImagePathAndName:
                    require("@/assets/images/skype.png")
            };
        }
    }
