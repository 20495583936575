
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsFullStack extends Vue {
        // Data function
        public data() {
            return {
                airVpnImagePathAndName:
                    require("@/assets/images/air_vpn.png"),

                awsImagePathAndName:
                    require("@/assets/images/aws.png"),

                biglyBtImagePathAndName:
                    require("@/assets/images/bigly_bt.png"),

                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(240, 10%, 40%), hsl(0, 0%, 22%))",

                chromeImagePathAndName:
                    require("@/assets/images/chrome.png"),

                gulpImagePathAndName:
                    require("@/assets/images/gulp.png"),

                mongodbImagePathAndName:
                    require("@/assets/images/mongodb.png"),

                pugImagePathAndName:
                    require("@/assets/images/pug.png"),

                vueImagePathAndName:
                    require("@/assets/images/vue.png"),

                webpackImagePathAndName:
                    require("@/assets/images/webpack.png"),

                yarnImagePathAndName:
                    require("@/assets/images/yarn.png"),
            };
        }
    }
