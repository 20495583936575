
    import CarouselSkillCard from "@/components/carousel_cards/carousel_skill_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselSkillCard
        },
    })

    // *****************************************************************************************************************
    export default class SkillsLanguage extends Vue {
        // Data function
        public data() {
            return {

                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(150, 10%, 40%), hsl(0, 0%, 22%))",

                cSharpImagePathAndName:
                    require("@/assets/images/c_sharp.png"),

                cPlusPlusImagePathAndName:
                    require("@/assets/images/c_plus_plus.png"),

                glslImagePathAndName:
                    require("@/assets/images/glsl.png"),

                javaImagePathAndName:
                    require("@/assets/images/java.png"),

                javascriptImagePathAndName:
                    require("@/assets/images/javascript.png"),

                pythonImagePathAndName:
                    require("@/assets/images/python.png"),

                typescriptImagePathAndName:
                    require("@/assets/images/typescript.png"),
            };
        }
    }
